import {
  GET_PREGUNTAS_FRECUENTES,
  GET_PREGUNTAS_SECCION,
  GET_PREGUNTAS_TIPO,
  GET_PREGUNTAS_MAP

} from "../types"
const initialState = {
  PreguntasFrecuentes: [],
  TipoPregunta: [],
  SeccionPregunta: [],
  PreguntasMap: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PREGUNTAS_FRECUENTES:
      return {
        ...state,
        PreguntasFrecuentes: action.payload,
      }
    case GET_PREGUNTAS_TIPO:
      return {
        ...state,
        TipoPregunta: action.payload,
      }
    case GET_PREGUNTAS_SECCION:
      return {
        ...state,
        SeccionPregunta: action.payload,
      }
    case GET_PREGUNTAS_MAP:
      return {
        ...state,
        PreguntasMap: action.payload,
      }
    default:
      return state
  }
}
