import { GET_SIAS, LOGOUT, GET_SIAS_STATUS } from "../types"
const initialState = {
  Sias: [],
  status: 0,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SIAS:
      return {
        ...state,
        Sias: action.payload,
      }
    case LOGOUT:
      return {
        Sias: [],
      }
    case GET_SIAS_STATUS:
      return {
        ...state,
        status: action.payload,
      }
    default:
      return state
  }
}
