import { GET_EXCESOS, LOGOUT, GET_STATUS_EXCESOS } from "../../types"
const initialState = {
  Excesos: [],
  status: 0,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EXCESOS:
      return {
        ...state,
        Excesos: action.payload,
      }
    case LOGOUT:
      return {
        Excesos: [],
      }
    case GET_STATUS_EXCESOS:
      return {
        ...state,
        status: action.payload,
      }
    default:
      return state
  }
}
