export const LOGUEADO = "LOGUEADO"
export const RELOAD_FICHA = "RELOAD_FICHA"
export const LOGIN_TIPO = "LOGIN_TIPO"
export const LOGIN_HORA = "LOGIN_HORA"
export const TIMER_LOGIN = "TIMER_LOGIN"
export const GET_COD_AREA = "GET_COD_AREA"
export const GET_FASES = "GET_FASES"
export const VERIFICAR_AR_F = "VERIFICAR_AR_F"
export const GET_PDF_DESCARGADOS_EXPEDIENTE = "GET_PDF_DESCARGADOS_EXPEDIENTE"


//FAQ
export const GET_PREGUNTAS_FRECUENTES = "GET_PREGUNTAS_FRECUENTES"
export const GET_PREGUNTAS_SECCION = "GET_PREGUNTAS_SECCION"
export const GET_PREGUNTAS_TIPO = "GET_PREGUNTAS_TIPO"
export const GET_PREGUNTAS_MAP = "GET_PREGUNTAS_MAP"

//DASHBOARD - DATOS MI SUPER
export const GET_DATOS_MISUPER = "GET_DATOS_MISUPER"
export const GET_DATOS_MISUPER_EDITAR = "GET_DATOS_MISUPER_EDITAR"
export const GET_AFILIACION = "GET_AFILIACION"
export const GET_AGENTE_VENTA = "GET_AGENTE_VENTA"

export const GET_PDF_AFILIACION = "GET_PDF_AFILIACION"

//DASHBOARD - EXCEDENTES
export const GET_EXCEDENTES = "GET_EXCEDENTES"
export const GET_STATUS_EXCEDENTES = "GET_STATUS_EXCEDENTES"

//DASHBOARD - EXCESOS
export const GET_EXCESOS = "GET_EXCESOS"
export const GET_STATUS_EXCESOS = "GET_STATUS_EXCESOS"

//DASHBOARD - ATU
export const GET_ATU = "GET_ATU"
export const GET_ATU_STATUS = "GET_ATU_STATUS"

//DASHBOARD - COTIZACIONES
export const GET_COTIZACIONES = "GET_COTIZACIONES"
export const GET_COTIZACIONES_STATUS = "GET_COTIZACIONES_STATUS"

//DASHBOARD - RECLAMOSIF
export const GET_RECLAMOSIF = "GET_RECLAMOSIF"
export const GET_TRAMITES_STATUS = "GET_TRAMITES_STATUS"
export const GET_LAST_PAGE = "GET_LAST_PAGE"
export const GET_LAST_CANT = "GET_LAST_CANT"
export const GET_LAST_TOTAL = "GET_LAST_TOTAL"
export const GET_CURRENT_FILTROS = "GET_CURRENT_FILTROS"
export const GET_CURRENT_TRAMITES = "GET_CURRENT_TRAMITES"
export const GET_ENTIDADES_REQUERIDAS = "GET_ENTIDADES_REQUERIDAS"
export const GET_FOLIO_ANTECEDENTE = "GET_FOLIO_ANTECEDENTE"
export const GET_PDF_DESCARGADOS_IF = "GET_PDF_DESCARGADOS_IF"
export const GET_PDF_DESCARGADOS_IP = "GET_PDF_DESCARGADOS_IP"
export const GET_FOLIO_ANTECEDENTE_RESULT = "GET_FOLIO_ANTECEDENTE_RESULT"
export const GET_BUSCANDO_RECLAMOS = "GET_BUSCANDO_RECLAMOS"
export const CLOSE_RECLAMO = "CLOSE_RECLAMO"

//CERTIFICADOS - RNPI
export const GET_RNPI = "GET_RNPI"
export const GET_PDF_RNPI = "GET_PDF_RNPI"

//NOTIFICACIONES
export const GET_NOTIFICACIONES = "GET_NOTIFICACIONES"

//SIAS
export const GET_SIAS = "GET_SIAS"
export const GET_SIAS_STATUS = "GET_SIAS_STATUS"

//PARAMETROS
export const GET_PARAMETROS = "GET_PARAMETROS"
export const GET_REGION_ID = "GET_REGION_ID"
export const GET_COMUNAS = "GET_COMUNAS"

export const REQUIERE_ACTUALIZAR = "REQUIERE_ACTUALIZAR"
export const LOGOUT = "LOGOUT"
