import { LOGUEADO, LOGIN_TIPO, REQUIERE_ACTUALIZAR, LOGIN_HORA, TIMER_LOGIN } from "../../types"
import clienteAxios from "../../../Utils/axios"
import { toast } from "react-toastify"
import { navigate } from "gatsby"
const { clean } = require("../../../Utils/ValidateRut")

export function LoginTipoDocF(rut, num_serie) {
  return async dispatch => {
    try {
      dispatch(Login_Tipo_F(true))
      const dataForm = new FormData()
      dataForm.append("rut", clean(rut.slice(0, -1)))
      dataForm.append("num_serie", num_serie)

      const respuesta = await clienteAxios.post("LoginTipoDoc/", dataForm)

      if (respuesta.status === 200) {
        const dataFormToken = new FormData()
        dataFormToken.append("run", clean(rut.slice(0, -1)))
        dataFormToken.append("dv", rut.charAt(rut.length-1))
        dataFormToken.append("nombres", "")
        dataFormToken.append("apellido_p", "")
        dataFormToken.append("apellido_m", "")
        dataFormToken.append("ingreso_clave_unica", false)

        const respuestatoken = await clienteAxios.post(
          "AuthUserMiSuper/",
          dataFormToken
        )
        
        localStorage.setItem("token", respuestatoken.data.authuser)
        localStorage.setItem("refsh", respuestatoken.data.refresh)
        
        localStorage.setItem("rut", clean(rut.slice(0, -1)))
        localStorage.setItem("dv", rut.charAt(rut.length-1))
        
        dispatch(Tipo_Log_F("tipodoc"))
        localStorage.setItem("tipologin", "tipodoc")
  
        if (respuestatoken.data.rfc.requiere_actualizar === true) {
          localStorage.setItem("requiere_actualizar", true)
          localStorage.setItem("logueado", true)
          dispatch(Login_Tipo_F(false))

          navigate("/inicio/editar-perfil/")
        } else {
          localStorage.setItem("requiere_actualizar", false)
          localStorage.setItem("logueado", true)
          dispatch(Login_Tipo_F(false))
          navigate("/inicio/")
        }
      } else if (respuesta.status === 204) {
        toast.error("N° Serie inválido.")
        dispatch(Login_Tipo_F(false))
        localStorage.setItem("logueado", false)
      }
    } catch (error) {
      toast.error("Por el momento este servicio no está disponible. Prefiera Clave Única")
      dispatch(Login_Tipo_F(false))
    }
  }
}

const Login_Tipo_F = datos => ({
  type: LOGUEADO,
  payload: datos,
})

export function TipoLogueoF(tipo) {
  return async dispatch => {
    dispatch(Tipo_Log_F(tipo))
  }
}

const Login_Hora_F = hora => ({
  type: LOGIN_HORA,
  payload: hora,
})

export function HoraLogueoF(hora) {
  return async dispatch => {
    dispatch(Login_Hora_F(hora))
  }
}

const Tipo_Log_F = datos => ({
  type: LOGIN_TIPO,
  payload: datos,
})

const Requiere_F = datos => ({
  type: REQUIERE_ACTUALIZAR,
  payload: datos,
})

const Timer_F = datos => ({
  type: TIMER_LOGIN,
  payload: datos,
})

export function TimerF(val) {
  return async dispatch => {
    dispatch(Timer_F(val))
  }
}

export function RequiereActualizarF(actualizar) {
  return async dispatch => {
    if (actualizar === false || actualizar === "false") {
      localStorage.setItem("requiere_actualizar", false)
    }
    dispatch(Requiere_F(actualizar))
  }
}
