import { GET_RNPI, LOGOUT, GET_PDF_RNPI } from "../../types"
const initialState = {
  Rnpi: [],
  PDFRnpi: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RNPI:
      return {
        ...state,
        Rnpi: action.payload,
      }
    case LOGOUT:
      return {
        Rnpi: [],
      }
    case GET_PDF_RNPI:
      return {
        ...state,
        PDFRnpi: action.payload,
      }
    default:
      return state
  }
}
