import { GET_COTIZACIONES, LOGOUT, GET_COTIZACIONES_STATUS } from "../../types"
const initialState = {
  Cotizaciones: [],
  status: 0,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COTIZACIONES:
      return {
        ...state,
        Cotizaciones: action.payload,
      }
    case LOGOUT:
      return {
        Cotizaciones: [],
      }
    case GET_COTIZACIONES_STATUS:
      return {
        ...state,
        status: action.payload,
      }
    default:
      return state
  }
}
