import { GET_ATU, LOGOUT, GET_ATU_STATUS } from "../../types"
const initialState = {
  Atu: [],
  status: 0,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ATU:
      return {
        ...state,
        Atu: action.payload,
      }
    case LOGOUT:
      return {
        Atu: [],
      }
    case GET_ATU_STATUS:
      return {
        ...state,
        status: action.payload,
      }
    default:
      return state
  }
}
