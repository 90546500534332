import { GET_PDF_DESCARGADOS_EXPEDIENTE } from "../types"
const initialState = {
  PDFDescargadosExpediente: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PDF_DESCARGADOS_EXPEDIENTE:
      return {
        ...state,
        PDFDescargadosExpediente: action.payload,
      }

    default:
      return state
  }
}
