import { 
  GET_RECLAMOSIF,
  GET_TRAMITES_STATUS,
  GET_LAST_PAGE,
  GET_LAST_CANT,
  GET_LAST_TOTAL,
  GET_CURRENT_FILTROS,
  GET_CURRENT_TRAMITES,
  GET_FOLIO_ANTECEDENTE,
  GET_PDF_DESCARGADOS_IF,
  GET_PDF_DESCARGADOS_IP,
  GET_FASES, GET_BUSCANDO_RECLAMOS,
  GET_ENTIDADES_REQUERIDAS
} from "../../types"
import clienteAxios from "../../../Utils/axios"
import { toast } from "react-toastify"
//import axios from 'axios'
//import * as Sentry from '@sentry/gatsby';

export function ConsultasManejoPDFF(nreclamo) {
  return async (dispatch) => {
    try {
      // setDescargando(true)
      
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")
      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv", dvsession)
      dataForm.append("n_reclamo", nreclamo)

      const respuesta = await clienteAxios.post("ManejoSolicitudPDF/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      const link = document.createElement("a")
      link.setAttribute(
        "href",
        "data:application/octet-stream;base64," +
          encodeURIComponent(respuesta.data.pdf_text)
      )
      link.setAttribute("download", "reclamo_" + nreclamo + ".pdf")
      link.style.display = "none"
      document.body.appendChild(link)
      link.click()
      
    //   if(PDFDescargadosIf.length === 0 ){
    //   dispatch(Get_PDF_IF_F([{
    //     folio: nreclamo,
    //     pdf_text: respuesta.data.pdf_text
    //   }]))
    // }else{

    //   PDFDescargadosIf.push({
    //     folio: nreclamo,
    //     pdf_text: respuesta.data.pdf_text
    //   })
    //   dispatch(Get_PDF_IF_F(PDFDescargadosIf))

    // }
      
      // setDescargando(false)
      
      toast.success("Descarga exitosa.")
    } catch (error) {
      // Sentry.captureException(error);
      // setDescargando(false)
      toast.success("Ocurrio un error con el servidor, intente mas tarde.")
    }
  }
}


export function ConsultasMediacionPDFF(nreclamo) {
  return async (dispatch) => {
    try {
      // setDescargando(true)
      
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")
      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv", dvsession)
      dataForm.append("n_reclamo", nreclamo)

      const respuesta = await clienteAxios.post("MediacionPDF/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      const link = document.createElement("a")
      link.setAttribute(
        "href",
        "data:application/octet-stream;base64," +
          encodeURIComponent(respuesta.data.pdf_text)
      )
      link.setAttribute("download", "reclamo_" + nreclamo + ".pdf")
      link.style.display = "none"
      document.body.appendChild(link)
      link.click()
      
    //   if(PDFDescargadosIf.length === 0 ){
    //   dispatch(Get_PDF_IF_F([{
    //     folio: nreclamo,
    //     pdf_text: respuesta.data.pdf_text
    //   }]))
    // }else{

    //   PDFDescargadosIf.push({
    //     folio: nreclamo,
    //     pdf_text: respuesta.data.pdf_text
    //   })
    //   dispatch(Get_PDF_IF_F(PDFDescargadosIf))

    // }
      
      // setDescargando(false)
   
      toast.success("Descarga exitosa.")
    } catch (error) {
      // Sentry.captureException(error);
      // setDescargando(false)
      toast.success("Ocurrio un error con el servidor, intente mas tarde.")
    }
  }
}

export function ConsultasReclamosIfF() {
  return async dispatch => {
    try {
      dispatch(Get_Busqueda_F(true))
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")
      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv",  dvsession)

      const respuesta = await clienteAxios.post("ReclamosTramites/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      dispatch(Get_Status_F(respuesta.status))
      if (respuesta.data !== "") {
        dispatch(Get_Reclamos_If_F(respuesta.data.reclamo_prestador))
      } else {
        dispatch(Get_Reclamos_If_F([]))
      }
      dispatch(Get_Busqueda_F(false))
    } catch (error) {
      dispatch(Get_Status_F(500))
      dispatch(Get_Busqueda_F(false))
    }
  }
}

const Get_Status_F = datos => ({
  type: GET_TRAMITES_STATUS,
  payload: datos,
})

const Get_PDF_IF_F = datos => ({
  type: GET_PDF_DESCARGADOS_IF,
  payload: datos,
})

const Get_PDF_IP_F = datos => ({
  type: GET_PDF_DESCARGADOS_IP,
  payload: datos,
})

const Get_Busqueda_F = datos => ({
  type: GET_BUSCANDO_RECLAMOS,
  payload: datos,
})

export function ConsultasReclamosIfPDFF(nreclamo, setDescargando, PDFDescargadosIf) {
  return async (dispatch) => {
    try {
      

      setDescargando(true)
      
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")
      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv", dvsession)
      dataForm.append("n_reclamo", nreclamo)

      const respuesta = await clienteAxios.post("ReclamoIfPDF/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      const link = document.createElement("a")
      link.setAttribute(
        "href",
        "data:application/octet-stream;base64," +
          encodeURIComponent(respuesta.data.pdf_text)
      )
      link.setAttribute("download", "reclamo_" + nreclamo + ".pdf")
      link.style.display = "none"
      document.body.appendChild(link)
      link.click()
        
      if(PDFDescargadosIf === [] || PDFDescargadosIf === "" ){
      dispatch(Get_PDF_IF_F([{
        folio: nreclamo,
        pdf_text: respuesta.data.pdf_text
      }]))
    }else{

      PDFDescargadosIf.push({
        folio: nreclamo,
        pdf_text: respuesta.data.pdf_text
      })
      dispatch(Get_PDF_IF_F(PDFDescargadosIf))

    }
      setDescargando(false)

      toast.success("Descarga exitosa.")
    } catch (error) {
      // Sentry.captureException(error);
      setDescargando(false)
      toast.success("Ocurrio un error con el servidor, intente mas tarde.")
     
    }
  }
}

export function ConsultasReclamosIpPDFF(nreclamo, setDescargando, PDFDescargadosIp) {
  return async (dispatch) => {
    try {
      setDescargando(true)
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")
      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv", dvsession)
      dataForm.append("n_reclamo", nreclamo)

      const respuesta = await clienteAxios.post("ReclamoIpPDF/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      const link = document.createElement("a")
      link.setAttribute(
        "href",
        "data:application/octet-stream;base64," +
          encodeURIComponent(respuesta.data.pdf_text)
      )
      link.setAttribute("download", "reclamo_" + nreclamo + ".pdf")
      link.style.display = "none"
      document.body.appendChild(link)
      link.click()

      if( PDFDescargadosIp === [] || PDFDescargadosIp === "" ){
        dispatch(Get_PDF_IP_F([{
          folio: nreclamo,
          pdf_text: respuesta.data.pdf_text
        }]))
      }else{
  
        PDFDescargadosIp.push({
          folio: nreclamo,
          pdf_text: respuesta.data.pdf_text
        })
        dispatch(Get_PDF_IP_F(PDFDescargadosIp))
  
      }

      setDescargando(false)
      toast.success('Descarga exitosa')
    } catch (error) {
      // Sentry.captureException(error);
      setDescargando(false)
      toast.success("Ocurrio un error con el servidor, intente mas tarde.")
    }
  }
}


export function GetFolioReclamoF(nreclamo, location) {
  return async  (dispatch) => {

    dispatch(Get_Folio_F({folio: nreclamo, path: location}))
  }
}

const Get_Folio_F = datos => ({
  type: GET_FOLIO_ANTECEDENTE,
  payload: datos,
})


const Get_Reclamos_If_F = datos => ({
  type: GET_RECLAMOSIF,
  payload: datos,
})



export function ConsultasMediacionesIpPDFF(nreclamo, setDescargando, PDFDescargadosMediaciones) {
  return async (dispatch) => {
    try {
      setDescargando(true)
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")
      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv", dvsession)
      dataForm.append("n_reclamo", nreclamo)

      const respuesta = await clienteAxios.post("MediacionPDF/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      const link = document.createElement("a")
      link.setAttribute(
        "href",
        "data:application/octet-stream;base64," +
          encodeURIComponent(respuesta.data.pdf_text)
      )
      link.setAttribute("download", "reclamo_" + nreclamo + ".pdf")
      link.style.display = "none"
      document.body.appendChild(link)
      link.click()

      if( PDFDescargadosMediaciones === [] || PDFDescargadosMediaciones === "" ){
        dispatch(Get_PDF_IP_F([{
          folio: nreclamo,
          pdf_text: respuesta.data.pdf_text
        }]))
      }else{
  
        PDFDescargadosMediaciones.push({
          folio: nreclamo,
          pdf_text: respuesta.data.pdf_text
        })
        dispatch(Get_PDF_IP_F(PDFDescargadosMediaciones))
  
      }

      setDescargando(false)
      toast.success('Descarga exitosa')

    } catch (error) {
      // Sentry.captureException(error);
      setDescargando(false)
      toast.success("Ocurrio un error con el servidor, intente mas tarde.")
    }
  }
}



export function ConsultaGetFasesF() {
  return async dispatch => {
    try {
      const tokenaccess = localStorage.getItem("token")

      const respuesta = await clienteAxios.get("FasesSeguimiento/", {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      dispatch(Get_Fases_F(respuesta.data.fases_seguimiento))
    
    } catch (error) {
      // Sentry.captureException(error);
    }
  }
}

const Get_Fases_F = datos => ({
  type: GET_FASES,
  payload: datos,
})


export function SetLastPage(page) {
  return async dispatch => {
    dispatch(Get_Last_Page(page))
  }
}

const Get_Last_Page = datos => ({
  type: GET_LAST_PAGE,
  payload: datos,
})

export function SetLastCant(cant) {
  return async dispatch => {
    dispatch(Get_Last_Cant(cant))
  }
}

const Get_Last_Cant = datos => ({
  type: GET_LAST_CANT,
  payload: datos,
})

export function SetLastTotal(total) {
  return async dispatch => {
    dispatch(Get_Last_Total(total))
  }
}

const Get_Last_Total = datos => ({
  type: GET_LAST_TOTAL,
  payload: datos,
})

export function SetCurrentTramites(total) {
  return async dispatch => {
    dispatch(Get_Current_Tramites(total))
  }
}

const Get_Current_Tramites = datos => ({
  type: GET_CURRENT_TRAMITES,
  payload: datos,
})

export function SetCurrentFiltros(filtros) {
  return async dispatch => {
    dispatch(Get_Current_Filtros(filtros))
  }
}

const Get_Current_Filtros = datos => ({
  type: GET_CURRENT_FILTROS,
  payload: datos,
})

export function SetEntidadesRequeridas(entidades) {
  return async dispatch => {
    dispatch(Get_Entidades_Requeridas(entidades))
  }
}

const Get_Entidades_Requeridas = datos => ({
  type: GET_ENTIDADES_REQUERIDAS,
  payload: datos,
})