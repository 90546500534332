import { combineReducers } from "redux"
import LoginReducer from "./LoginReducer"
import FaqReducer from "./FaqReducer"
import SiasReducer from "./SiasReducer"

// Dashboard Reducers
import DatosReducer from "./DashboardReducers/DatosReducer"
import ExcedentesReducer from "./DashboardReducers/ExcedentesReducer"
import ExcesosReducer from "./DashboardReducers/ExcesosReducer"
import AtuReducer from "./DashboardReducers/AtuReducer"
import CotizacionesReducer from "./DashboardReducers/CotizacionesReducer"
import ReclamosTramitesReducer from "./DashboardReducers/ReclamosTramitesReducer"
//Certificados Reducers
import RnpiReducer from "./CertificadosReducers/RnpiReducer"

//Notificaciones Reducers
import NotificacionesReducer from "./NotificacionesReducer"

//Parametros Reducers
import ParametrosReducer from "./ParametrosReducer"
import AfiliacionReducer from "./CertificadosReducers/AfiliacionReducer"
import Antecedentes from "./AntecedentesReducer"
import ExpedienteReducer from "./ExpedienteReducer"


export default combineReducers({
  Login: LoginReducer,
  Faq: FaqReducer,
  Datosmisuper: DatosReducer,
  Excedentes: ExcedentesReducer,
  Excesos: ExcesosReducer,
  Atu: AtuReducer,
  Cotizaciones: CotizacionesReducer,
  ReclamosTramites: ReclamosTramitesReducer,
  Rnpi: RnpiReducer,
  Notificaciones: NotificacionesReducer,
  Sias: SiasReducer,
  ParametrosEditar: ParametrosReducer,
  Afiliacion: AfiliacionReducer,
  Antecedentes,
  ExpedienteReducer
  // user
})

