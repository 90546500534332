import { GET_RECLAMOSIF, 
  LOGOUT, 
  GET_TRAMITES_STATUS, 
  GET_CURRENT_FILTROS, 
  GET_LAST_PAGE, 
  GET_LAST_CANT, 
  GET_CURRENT_TRAMITES, 
  GET_LAST_TOTAL, 
  GET_FOLIO_ANTECEDENTE, 
  GET_PDF_DESCARGADOS_IF, 
  GET_PDF_DESCARGADOS_IP, 
  GET_FASES, 
  GET_BUSCANDO_RECLAMOS, 
  CLOSE_RECLAMO,
  GET_ENTIDADES_REQUERIDAS
} from "../../types"
// se inicia busqueda en true para que se entienda desde el minuto uno que esta buscando los reclamos
const initialState = {
  buscando: true,
  lastPage: 0,
  lastCant: 10,
  lastTotal: 0,
  currentFiltros: [],
  currentTramites: [],
  ReclamosTramites: [],
  status: 0,
  folio: [],
  PDFDescargadosIf: [],
  PDFDescargadosIp: [],
  FasesSeguimiento: [],
  EntidadesRequeridas: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RECLAMOSIF:
      return {
        ...state,
        ReclamosTramites: action.payload,
      }
    case LOGOUT:
      return {
        buscando: true,
        lastPage: 0,
        lastCant: 10,
        lastTotal: 0,
        currentFiltros: [],
        currentTramites: [],
        ReclamosTramites: [],
        status: 0,
        folio: [],
        PDFDescargadosIf: [],
        PDFDescargadosIp: [],
        FasesSeguimiento: [],
        EntidadesRequeridas: []
      }
      case GET_TRAMITES_STATUS:
        return{
          ...state,
          status: action.payload
        }

    case GET_FOLIO_ANTECEDENTE:
      return {
        ...state,
        folio: action.payload
      }

    case GET_PDF_DESCARGADOS_IF:
      return{
        ...state,
        PDFDescargadosIf: action.payload
      }
      
    case GET_PDF_DESCARGADOS_IP:
      return{
        ...state,
        PDFDescargadosIp: action.payload
      }

    case GET_FASES:
      return{
        ...state,
        FasesSeguimiento: action.payload
      }

    case GET_BUSCANDO_RECLAMOS:
      return{
        ...state,
        buscando: action.payload,
      }

    case GET_LAST_PAGE:
      return{
        ...state,
        lastPage: action.payload,
      }
    
    case GET_LAST_CANT:
      return{
        ...state,
        lastCant: action.payload,
      }

    case GET_LAST_TOTAL:
      return{
        ...state,
        lastTotal: action.payload,
      }

    case GET_CURRENT_TRAMITES:
      return{
        ...state,
        currentTramites: action.payload,
      }

    case GET_CURRENT_FILTROS:
      return{
        ...state,
        currentFiltros: action.payload,
      }
      
    case GET_ENTIDADES_REQUERIDAS:
      return{
        ...state,
        EntidadesRequeridas: action.payload,
      }

    case CLOSE_RECLAMO:
      var auxArr = state.ReclamosTramites
      const index = auxArr.findIndex( reclamo => reclamo.n_reclamo === action.folio )
      auxArr[index].is_recurso_upload = false
      auxArr[index].is_recurso_upload_str = 'Ya se agregó un recurso'

      var auxArr2 = state.currentTramites
      const index2 = auxArr2.findIndex( reclamo => reclamo.n_reclamo === action.folio )
      auxArr2[index2].is_recurso_upload = false
      auxArr2[index2].is_recurso_upload_str = 'Ya se agregó un recurso'
      return{
        ...state,
        ReclamosTramites: auxArr,
        currentTramites: auxArr2
      }

    default:
      return state
  }
}
