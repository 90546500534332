import { LOGUEADO, LOGIN_TIPO, REQUIERE_ACTUALIZAR, LOGIN_HORA, TIMER_LOGIN } from "../types"
const initialState = {
  Logueado: false,
  LoginTipo: "",
  requiere_actualizar: false,
  hora: new Date(),
  timer_login: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGUEADO:
      return {
        ...state,
        Logueado: action.payload,
      }
    case LOGIN_TIPO:
      return {
        ...state,
        LoginTipo: action.payload,
      }
    case REQUIERE_ACTUALIZAR:
      return {
        ...state,
        requiere_actualizar: action.payload,
      }
    case LOGIN_HORA:
      return {
        ...state,
        hora: action.payload,
      }
    case TIMER_LOGIN:
      return {
        ...state,
        timer_login: action.payload,
      }

    default:
      return state
  }
}
