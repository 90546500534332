import { GET_EXCEDENTES, LOGOUT, GET_STATUS_EXCEDENTES } from "../../types"
const initialState = {
  Excedentes: [],
  status: 0,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EXCEDENTES:
      return {
        ...state,
        Excedentes: action.payload,
      }
    case LOGOUT:
      return {
        Excedentes: [],
      }
    case GET_STATUS_EXCEDENTES:
      return {
        ...state,
        status: action.payload,
      }
    default:
      return state
  }
}
