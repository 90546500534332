import { GET_PARAMETROS, GET_REGION_ID, GET_COMUNAS } from "../types"
const initialState = {
  ParametrosEditar: [],
  RegionId: 0,
  Comunas: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PARAMETROS:
      return {
        ...state,
        ParametrosEditar: action.payload,
      }
    case GET_REGION_ID:
      return {
        RegionId: action.payload,
      }
    case GET_COMUNAS:
      return {
        ...state,
        Comunas: action.payload,
      }
    default:
      return state
  }
}
