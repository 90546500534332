import { GET_FOLIO_ANTECEDENTE_RESULT, VERIFICAR_AR_F } from "../types"
const initialState = {
  FolioAntecedente: 0,
  Existentes: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FOLIO_ANTECEDENTE_RESULT:
      return {
        ...state,
        FolioAntecedente: action.payload,
      }
    case VERIFICAR_AR_F:
      return{
        ...state,
        Existentes: action.payload,
      }
    default:
      return state
  }
}
