import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { navigate } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    fontWeight: "bold",
    cursor: "pointer",
    textTransform: 'capitalize'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export default function IconBreadcrumbs({ location}) {

  const classes = useStyles();

  const [Values, setValues] = useState([])
  const [Follio, setFollio] = useState(0)

  useEffect(() => {
    setValues(location.pathname.split('/'))
    setFollio(localStorage.getItem('folio'))

  }, [])

  return (
    <div className="contenedor">
    <Breadcrumbs aria-label="breadcrumb" >
      
    {Values.filter(val => val !== "" && val !== null).map((dato, i) => (
      <Link 
      key={i}
      color={ 
      Values.length === 6 && dato === 'ingresar-antecedentes' ? 'primary' :
      Values.length === 6 && dato === 'agregar-antecedentes' ? 'primary' :
      Values.length === 6 && dato === 'agregar-recurso' ? 'primary' :
      Values.length === 6 && dato === 'presentar-recurso' ? 'primary' :
      Values.length === 5 && dato === 'certificados-de-reclamos' || dato === 'certificados-rnpi' ? 'primary' :
      Values.length === 5 && dato === 'detalle' && location.pathname === `/inicio/tramites/detalle/` ? 'primary' :
      Values.length === 5 && dato === 'antecedentes' ? 'primary' :
      Values.length === 5 && dato === 'recurso' ? 'primary' :
      Values.length === 4 && dato !== 'inicio' ? "primary" :
      'textPrimary' 
      } 

      className={classes.link} 
      onClick={() => navigate(
        dato === 'agregar-antecedentes' ?  `/inicio/tramites/detalle/${dato}/` :
        dato === 'agregar-recursos' ?  `/inicio/tramites/detalle/${dato}/` :
        dato === 'ingresar-antecedentes' ?  `/inicio/reclamos/antecedentes/${dato}/` :
        dato === 'presentar-recurso' ?  `/inicio/reclamos/recurso/${dato}/` :
        dato === 'certificados-rnpi' || dato === 'certificados-de-reclamos' ?  `/inicio/certificados/${dato}/` :
        dato === 'detalle' && location.pathname === '/inicio/tramites/detalle/' ? "/inicio/tramites/detalle/" :
        dato === 'detalle'  && location.pathname === '/inicio/atu/detalle/' ? "/inicio/atu/detalle/" :
        dato === 'detalle' ?  `/inicio/tramites/${dato}/` :
        dato === 'recurso' ?  `/inicio/reclamos/${dato}/` :
        dato === 'antecedentes' ?  `/inicio/reclamos/${dato}/` :
        dato !== 'inicio' ? `/inicio/${dato}/` : '/inicio/'
        )}>

        {
        dato === 'atu' ? 'Atención de Usuarios':
        dato === 'tramites' ? 'Seguimiento de Trámites':
        dato === 'certificados-de-reclamos' ? 'Trámites':
        dato === 'certificados-rnpi' ? 'Registro Nacional de Prestadores Individuales de Salud':
        dato === 'faq' ? 'Preguntas Frecuentes':
        dato === 'antecedentes' ? ` Ingresar Antecedentes `:
        dato === 'detalle' && Values.length === 5  ? "Detalle" :
        dato === 'agregar-antecedentes' && Values.length === 6 ? "Documentación Adicional" :
        dato === 'agregar-recurso' && Values.length === 6 ? "Presentación de Recurso" :
        dato === 'ingresar-antecedentes' ? `${Follio}`:
        dato === 'presentar-recurso' ? `${Follio}`:
        dato === 'recurso' ? ` Presentar Recurso`:
        dato.replace('-', ' ').replace('-', ' ') }
      </Link>

      ))}

      
      
    </Breadcrumbs>
    </div>
  );
}