import { GET_PDF_AFILIACION } from "../../types"
const initialState = {
  PDFAfiliacion: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PDF_AFILIACION:
      return {
        ...state,
        PDFAfiliacion: action.payload,
      }
    default:
      return state
  }
}
