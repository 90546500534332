import {
  GET_DATOS_MISUPER,
  GET_AFILIACION,
  GET_AGENTE_VENTA,
  LOGOUT,
  RELOAD_FICHA,
  GET_COD_AREA
} from "../../types"
const initialState = {
  DatosMiSuper: [],
  Afiliacion: [],
  AgenteVentas: [],
  Reload: false,
  CodArea: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DATOS_MISUPER:
      return {
        ...state,
        DatosMiSuper: action.payload,
      }
    case GET_AFILIACION:
      return {
        ...state,
        Afiliacion: action.payload,
      }
    case GET_AGENTE_VENTA:
      return {
        ...state,
        AgenteVentas: action.payload,
      }
    case LOGOUT:
      return {
        DatosMiSuper: [],
        Afiliacion: [],
        AgenteVentas: [],
      }
    case RELOAD_FICHA:
      return {
        ...state,
        Reload: action.payload,
      }
    case GET_COD_AREA:
      return {
        ...state,
        CodArea: action.payload,
      }


    default:
      return state
  }
}
