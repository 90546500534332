import { GET_NOTIFICACIONES, LOGOUT } from "../types"
const initialState = {
  Notificaciones: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICACIONES:
      return {
        ...state,
        Notificaciones: action.payload,
      }
    case LOGOUT:
      return {
        Notificaciones: [],
      }
    default:
      return state
  }
}
