import {
  GET_DATOS_MISUPER,
  GET_AFILIACION,
  GET_AGENTE_VENTA,
  LOGOUT,
  RELOAD_FICHA,
} from "../../types"
import { toast } from "react-toastify"
import clienteAxios from "../../../Utils/axios"
import { navigate } from "gatsby"
//import * as Sentry from '@sentry/gatsby';

export function ConsultaFichaF() {
  return async dispatch => {
    try {
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")

      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv", dvsession)

      const respuesta = await clienteAxios.post("FichaCiudadano/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })


      dispatch(Get_Datos_F(respuesta.data.arrfichaCiudadano))

    } catch (error) {
      // Sentry.captureException(error);
      localStorage.clear()
      navigate("/")
    }
  }
}

const Get_Datos_F = datos => ({
  type: GET_DATOS_MISUPER,
  payload: datos,
})

export function ConsultaAfiliacionF() {
  return async dispatch => {
    try {
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")

      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv", dvsession)

      const respuesta = await clienteAxios.post("Afiliacion/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      if (respuesta.data !== "") {
        dispatch(Get_Afiliacion_F(respuesta.data.afiliacion))
      } else {
        dispatch(Get_Afiliacion_F([]))
      }
    } catch (error) {
      // Sentry.captureException(error);
    }
  }
}

const Get_Afiliacion_F = datos => ({
  type: GET_AFILIACION,
  payload: datos,
})

export function ConsultaAgentesF() {
  return async dispatch => {
    try {
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")

      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv", dvsession)

      const respuesta = await clienteAxios.post("AgenteDeVentas/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      if (respuesta.data !== "") {
        dispatch(Get_Agentes_F(respuesta.data.agentedeventas))
      } else {
        dispatch(Get_Agentes_F([]))
      }
    } catch (error) {
      // Sentry.captureException(error);
    }
  }
}

const Get_Agentes_F = datos => ({
  type: GET_AGENTE_VENTA,
  payload: datos,
})

export function LogoutF() {
  return async dispatch => {
    try {
      dispatch(Logout_F())
    } catch (error) {
      // Sentry.captureException(error);
    }
  }
}

const Logout_F = () => ({
  type: LOGOUT,
})

// EDITAR PERFIL

export function ActualizarFicha(Data, image, setActualizando, NumeroTlf) {
  return async dispatch => {
    try {
      dispatch(Reload_Ficha_F(false))
      const tokenaccess = localStorage.getItem("token")

      let imagen = Data.foto_text
      if (image !== "") {
        imagen = image
      }

      if (Data.fecha_nacimiento.substring(4,5) === '-' && Data.fecha_nacimiento.substring(7,8) === '-' && Data.fecha_nacimiento.length === 10){
        let datos = {
          ficha_ciudadano: {
            run: Data.run,
            dv: Data.dv,
            nombre: Data.nombre,
            apellido_p: Data.apellido_p,
            apellido_m: Data.apellido_m,
            fecha_nacimiento: Data.fecha_nacimiento,
            genero_id: Data.genero_id,
            sexo_id: Data.sexo_id,
            nacionalidad_id: Data.nacionalidad_id,
            pais_origen_id: Data.pais_origen_id,
            email: Data.email,
            region_id: Data.region_id,
            comuna_id: Data.comuna_id,
            direccion_tipo_id: Data.direccion_tipo_id,
            direccion_nombre: Data.direccion_nombre,
            direccion_nro: Data.direccion_nro,
            direccion_nro_dpto: Data.direccion_nro_dpto,
            foto_text: imagen,
            telefono_uno: NumeroTlf,
            telefono_dos: Data.telefono_dos,
            facebook: Data.facebook,
            twitter: Data.twitter,
            notificar_perfil: Data.notificar_perfil,
            notificar_tramite_id: Data.notificar_tramite_id,
            cod_area: Data.cod_area,
          },
        }
  
        const respuesta = await clienteAxios.post(
          "FichaCiudadanoEditar/",
          datos,
          {
            headers: {
              Authorization: "Bearer " + tokenaccess,
              accept: "application/json",
            },
          }
        )
  
        if (respuesta.status === 200) {
  
          dispatch(Reload_Ficha_F(true))
          localStorage.setItem("requiere_actualizar", false)
          toast.success("Información actualizada.")
  
          setTimeout(() => {
            navigate('/inicio/')
          }, 2000);
  
        }
        setActualizando(false)
        dispatch(Reload_Ficha_F(false))

      }else{
        dispatch(Reload_Ficha_F(true))
        localStorage.setItem("requiere_actualizar", false)
        toast.info("Formato fecha de nacimiento incorrecto, ingrese fecha como dd-mm-aaaa.")

        setTimeout(() => {
          navigate('/inicio/')
        }, 8000);

      }

      
    } catch (error) {
      // Sentry.captureException(error);
    }
  }
}

const Reload_Ficha_F = datos => ({
  type: RELOAD_FICHA,
  payload: datos,
})

