import React from 'react';
import HeaderComponent from "../components/templates/HeaderComponents/header"
import FooterComponent from "../components/templates/FooterComponents/Footer"
import Breadcrumb from '../Utils/Breadcrumb';
import { navigate } from 'gatsby'
import {
  FaQuestionCircle,
  FaTwitterSquare,
  FaFacebookSquare,
  FaPhoneSquareAlt,
  FaInstagramSquare
} from "react-icons/fa"

const LayoutTemplate = ({ props }) => {
  return (
    <>
      {props.location.pathname !== "/validacion" &&
        props.location.pathname !== "/validacion/" && (
          <HeaderComponent
            siteTitle={props.title}
            location={props.location.pathname}
          />
        )}

      {props.location.pathname !== "/validacion" &&
        props.location.pathname !== "/validacion/" && props.location.pathname !== "/" && props.location.pathname !== "/inicio/" && (
          <>
            <Breadcrumb location={props.location} />
          </>
        )}

      {props.location.pathname !== "/validacion" &&
        props.location.pathname !== "/validacion/" && props.location.pathname !== "/" &&
        <button className="button-preguntas-float d-none d-lg-block" onClick={() => navigate('/inicio/faq/')} ><span >Preguntas Frecuentes <FaQuestionCircle className="ml-2" /> </span></button>
      }

      <div className=" contenido-ss">
        {props.children}
        <div className="contenedor d-lg-none my-5">
          <div className="row ">
            <div className="col-12 col-sm-6 col-lg-6 ">
              <a href="tel:6008369000" className="font-weight-bold my-1 text-left" style={{ color: '#b7b3b3' }}><FaPhoneSquareAlt className="mx-2 mt-1 icon-footer-ss" />600 836 9000</a>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 ">
              <a href="https://www.instagram.com/superdesaludchile/ " target="_blank" style={{ color: '#b7b3b3' }} className="font-weight-bold my-1 text-left"><FaInstagramSquare className="mx-2 mt-1 icon-footer-ss" />@SuperdeSaludChile</a>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 ">
              <a href="https://twitter.com/SuperDeSalud" target="_blank" className="font-weight-bold my-1 text-left" style={{ color: '#b7b3b3' }}><FaTwitterSquare className="mx-2 mt-1 icon-footer-ss" />@SuperDeSalud </a>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 ">
              <a href="https://www.facebook.com/SuperdeSaludChile " target="_blank" style={{ color: '#b7b3b3' }} className="font-weight-bold my-1 text-left"><FaFacebookSquare className="mx-2 mt-1 icon-footer-ss" />@SuperdeSaludChile</a>
            </div>
          </div>
        </div>
      </div>

      {props.location.pathname !== "/validacion" &&
        props.location.pathname !== "/validacion/" && (
          <FooterComponent
            location={props.location.pathname} />
        )}
    </>
  );
}

export default LayoutTemplate;