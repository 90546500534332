import React, { useEffect } from "react"
import "../../../Utils/fonts/index.css"
import "react-toastify/dist/ReactToastify.css"
import "./header.css"
import "bootstrap/dist/css/bootstrap.min.css"
import Image from "react-bootstrap/Image"
import Helmet from "react-helmet"
import { FaSignOutAlt, FaUserEdit } from "react-icons/fa"
import { ConsultaPreguntasF } from "../../../state/actions/Faq/FaqActions"
import {
  ConsultasReclamosIfF,
  GetFolioReclamoF,
  ConsultaGetFasesF
} from "../../../state/actions/Dashboard/ReclamosTramitesActions"

import logo from "../../../images/unnamed.jpg"
import { navigate } from "gatsby"
import {
  LogoutF,
  ConsultaFichaF,
} from "../../../state/actions/Dashboard/DatosActions"
import {
  RequiereActualizarF,
  TipoLogueoF,
  HoraLogueoF,
  TimerF
} from "../../../state/actions/Login/LoginActions"
import { useDispatch, useSelector } from "react-redux"
import imagen from "../../../images/avatar.png"
import clienteAxios from "../../../Utils/axios"
import Swal from 'sweetalert2'
import { logoutClaveUnica } from '../../../Utils/LogoutClaveUnica';

const HeaderComponent = ({ siteTitle, location }) => {
  const DatosMiSuper = useSelector(state => state.Datosmisuper.DatosMiSuper)
  const HoraInicio = useSelector(state => state.Login.hora)
  const Timer = useSelector(state => state.Login.timer_login)
  const PreguntasFrecuentes = useSelector(
    state => state.Faq.PreguntasFrecuentes
  )

  const dispatch = useDispatch()

  const CerrarSesion = () => {

    Swal.fire({
      title: '¿Estás seguro de cerrar sesión?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, cerrar sesión!',
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        
        logoutClaveUnica()

        let timerInterval
        Swal.fire({
          title: 'Cerrando sesión',
          // html: 'I will close in <b></b> milliseconds.',
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(() => {
              const content = Swal.getContent()
              if (content) {
                const b = content.querySelector('b')
                if (b) {
                  b.textContent = Swal.getTimerLeft()
                }
              }
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            
            window.location.href =
            "https://accounts.claveunica.gob.cl/api/v1/accounts/app/logout"

            navigate("/")
            localStorage.clear()
            const ClearState = () => dispatch(LogoutF())
            ClearState()
          }
        })
      }
    })
    return;
  }

  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = (
  //     // hotjar
  //     (function(h,o,t,j,a,r){
  //         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  //         h._hjSettings={hjid:3860406,hjsv:6};
  //         a=o.getElementsByTagName('head')[0];
  //         r=o.createElement('script');r.async=1;
  //         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  //         a.appendChild(r);
  //     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
  //   )
  //   document.body.appendChild(script);
  // }, []);

  useEffect(() => {
    const requiere_actualizar = localStorage.getItem("requiere_actualizar")
    const tipologin = localStorage.getItem("tipologin")
    const token = localStorage.getItem("token")
    const folio = localStorage.getItem("folio")
    const refsh = localStorage.getItem("refsh")
    var horaLog = HoraInicio

    const changeHora = (hora) => {
      dispatch(HoraLogueoF(hora))
      horaLog = hora
    }

    const TokenGet = async () => {
      try {
        const dataForm = new FormData()
        dataForm.append("refresh", refsh)

        const respuesta = await clienteAxios.post(
          "token/refresh/",
          dataForm,
          {
            headers: {
              Authorization: "Bearer " + token,
              accept: "application/json",
            },
          }
        )
        localStorage.setItem("token", respuesta.data.access)
        localStorage.setItem("refsh", respuesta.data.refresh)
        changeHora(new Date())
        navigate("/inicio/")
      } catch (error) {
        navigate("/")
        localStorage.clear()
      }
    }

    //DETECTAR INACTIVIDAD CADA 3 HORAS
    if(!Timer && location !== "/"){
      var activo = false;
      document.onmousemove = function(){
        activo = true;
      };
      setInterval (function() {
        const now = new Date()
        if(!activo ){
          localStorage.clear()
          const ClearState = () => dispatch(LogoutF())
          ClearState()
          navigate("/")
        }else{
          if( now.getTime() - horaLog.getTime() >= 10000000 ){
            TokenGet()
          }
          activo = false;
        }
      }, 2700000);
      dispatch(TimerF(true))
    }
      
    if (location === "/") {
      if (token !== "" && token !== null && token !== undefined) {
        TokenGet()
        return
      }
    }

    if (
      location !== "/" &&
      location !== "/validacion" &&
      location !== "/validacion"
    ) {
      if (token === "" || token === null || token === undefined) {
        navigate("/")
        localStorage.clear()
        const ClearState = () => dispatch(LogoutF())
        ClearState()
        return
      } else {
        const TokenGet = async () => {
          try {
            const dataForm = new FormData()
            dataForm.append("refresh", refsh)

            const respuesta = await clienteAxios.post(
              "token/refresh/",
              dataForm,
              {
                headers: {
                  Authorization: "Bearer " + token,
                  accept: "application/json",
                },
              }
            )
            localStorage.setItem("token", respuesta.data.access)
            localStorage.setItem("refsh", respuesta.data.refresh)
            changeHora(new Date())
          } catch (error) {
            navigate("/")
            localStorage.clear()
          }
        }

        TokenGet()
      }

      if (DatosMiSuper.length === 0) {
        const Ficha = () => dispatch(ConsultaFichaF())
        Ficha()

        const ConsultaReclamosIf = () => dispatch(ConsultasReclamosIfF())
        ConsultaReclamosIf()

        const FolioAntecedenteF = () =>
          dispatch(GetFolioReclamoF(folio, location.pathname))
        FolioAntecedenteF()
      }

        
        const GetFases = () => dispatch(ConsultaGetFasesF())
        GetFases()

      const Requiere = () => dispatch(RequiereActualizarF(requiere_actualizar))
      Requiere()

      const Logueo = () => dispatch(TipoLogueoF(tipologin))
      Logueo()

      if (PreguntasFrecuentes.length === 0) {
        const ConsultarFaq = () => dispatch(ConsultaPreguntasF())
        ConsultarFaq()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{siteTitle ? siteTitle + " - Mi Súper" : "Mi Súper"}</title>
        <meta name="description" content={"Mi Súper"} />
      </Helmet>
      <header className="d-flex header-resposive-ss">
        <Image src={logo} className="logo-responsive" />
      </header>
      {location !== "/" && location !== "/validacion/" && (
        <div className="personal-info-ss contenedor mt-5">
          <div>
            {DatosMiSuper !== null && (
              <h1
                // style={{ fontSize: 24, fontWeight: "bold" }}
                className="my-auto font-weight-bold"
              >
                Bienvenido/a
                {
                DatosMiSuper.nombre !== null &&
                   " " +
                    DatosMiSuper.nombre +
                    " " +
                    DatosMiSuper.apellido_p +
                    " " +
                    DatosMiSuper.apellido_m
                  }
              </h1>
            )}
            <h1 className="py-0 font-weight-bold">
              {" "}
              Accede a tus trámites y servicios en línea disponibles
            </h1>
          </div>
          <div className="perfil-ss">
            <Image
              src={
                DatosMiSuper.foto_text !== "" && DatosMiSuper.foto_text !== null
                  ? DatosMiSuper.foto_text
                  : 'https://datos.superdesalud.gob.cl/avatar.jpeg'
              }
              alt="Imagen de Perfil"
              className="image-header-ss"
            />
            <div className="usuario-ss px-3">
              <p className="my-0" style={{ fontSize: 14, fontWeight: "bold" }}>
                {DatosMiSuper.nombre !== null
                  ? DatosMiSuper.nombre +
                    " " +
                    DatosMiSuper.apellido_p +
                    " " +
                    DatosMiSuper.apellido_m
                  : null}
              </p>
              <p
                className="my-0"
                onClick={() => navigate("/inicio/editar-perfil/")}
                style={{
                  fontSize: 12,
                  fontWeight: "normal",
                  color: "#0073ba",
                  cursor: "pointer",
                }}
              >
                Editar Perfil <FaUserEdit />
              </p>
            </div>
            <div className="bar-perfil-ss"></div>
            <div onClick={() => CerrarSesion()}>
              <p
                className="my-0 faq-perfil-ss  text-center"
                style={{ cursor: "pointer" }}
              >
                Cerrar Sesión <FaSignOutAlt />
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default HeaderComponent
