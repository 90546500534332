import React, {useEffect} from 'react';
import logo from '../../../images/footer.png';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Home from '@material-ui/icons/Home';
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"
import {
  LogoutF,
} from "../../../state/actions/Dashboard/DatosActions"

import {
    FaQuestionCircle,
    FaUserEdit,
    FaTwitterSquare,
    FaFacebookSquare,
    FaPhoneSquareAlt,
    FaInstagramSquare,
    FaTimesCircle,
  } from "react-icons/fa"
  import Swal from 'sweetalert2'
  import { logoutClaveUnica } from '../../../Utils/LogoutClaveUnica';

const FooterComponent = ({location}) => {

    const [value, setValue] = React.useState('inicio');
  
    const dispatch = useDispatch()
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
        
        if(location === '/inicio/'){
            setValue('inicio')
        }else if(location === '/inicio/faq/'){
            setValue('preguntas')
        }else if(location === '/inicio/editar-perfil/'){
            setValue('editar')
        }
    }, [])
    

      const CerrarSesion = () => {

    Swal.fire({
      title: '¿Estás seguro de cerrar sesión?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, cerrar sesión!',
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        
        logoutClaveUnica()

let timerInterval
Swal.fire({
  title: 'Cerrando sesión',
  // html: 'I will close in <b></b> milliseconds.',
  timer: 2000,
  timerProgressBar: true,
  didOpen: () => {
    Swal.showLoading()
    timerInterval = setInterval(() => {
      const content = Swal.getContent()
      if (content) {
        const b = content.querySelector('b')
        if (b) {
          b.textContent = Swal.getTimerLeft()
        }
      }
    }, 100)
  },
  willClose: () => {
    clearInterval(timerInterval)
  }
}).then((result) => {
  /* Read more about handling dismissals below */
  if (result.dismiss === Swal.DismissReason.timer) {
    
    window.location.href =
    "https://accounts.claveunica.gob.cl/api/v1/accounts/app/logout"

    navigate("/")
    localStorage.clear()
    const ClearState = () => dispatch(LogoutF())
    ClearState()
  }
})
      }
    })

    return;

    
  }

    return ( 

        <>
        <footer className={location !== '/validacion' ? "footer-ss d-none d-lg-block" : "footer-ss"}>
            <div className="contenedor ">
                <div  className="d-flex">
                    <div>
                        <img src={logo} alt={"Logo SuperIntendencia de Salud"} className="imagen-footer"/>
                    </div>
                    <div className="ml-auto">
                        <a href="tel:6008369000" className="font-weight-bold my-1 d-block" style={{color: '#b7b3b3'}}><FaPhoneSquareAlt  className="mx-2 mt-1 icon-footer-ss" />600 836 9000</a>
                        <a href="https://twitter.com/SuperDeSalud" rel="noreferrer" target="_blank" className="font-weight-bold my-1 d-block" style={{color: '#b7b3b3'}}><FaTwitterSquare  className="mx-2 mt-1 icon-footer-ss" />@SuperDeSalud </a>
                    </div>
                    <div className="ml-auto">
                        <a href="https://www.instagram.com/superdesaludchile/ " rel="noreferrer" target="_blank" style={{color: '#b7b3b3'}} className="font-weight-bold my-1 d-block"><FaInstagramSquare  className="mx-2 mt-1 icon-footer-ss" />@SuperdeSaludChile</a>
                        <a href="https://www.facebook.com/SuperdeSaludChile " rel="noreferrer" target="_blank" style={{color: '#b7b3b3'}} className="font-weight-bold my-1 d-block"><FaFacebookSquare  className="mx-2 mt-1 icon-footer-ss" />@SuperdeSaludChile</a>
                    </div>
                </div>
            </div>
        </footer>


       {location !== '/' && location !== '/validacion' && 

       <BottomNavigation value={value} onChange={handleChange} className="fixed-bottom d-flex d-lg-none" showLabels>
            <BottomNavigationAction label="Inicio" value="inicio" icon={<Home />} onClick={() => navigate("/inicio/")} />
            <BottomNavigationAction label="Perfil" value="editar" icon={<FaUserEdit />} onClick={() => navigate("/inicio/editar-perfil/")} />
            <BottomNavigationAction label="Preguntas " value="preguntas" icon={<FaQuestionCircle />} onClick={() => navigate("/inicio/faq/")} />
            <BottomNavigationAction label="Salir " value="cerrar" onClick={() => CerrarSesion()} icon={<FaTimesCircle />} />
        </BottomNavigation>
        }
    </> 
        
     );
}
export default FooterComponent;