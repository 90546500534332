import React, { useEffect, useState } from "react"
import { Provider } from "react-redux"
import store from "../state/store"
import { navigate } from 'gatsby'
import Spinner from '../components/templates/Spinner'
import LayoutTemplate from './LayoutTemplate';

const LayoutProvider = props => {

  const [Log, setLog] = useState(true)
  const [Location, setLocation] = useState()
  useEffect(() => {
    const logueado = localStorage.getItem("logueado")
    const requiere_actualizar = localStorage.getItem("requiere_actualizar")
    setLocation(window.location.pathname)

    if (logueado === false || logueado === null || logueado === undefined) {
        navigate("/")
    } else if (requiere_actualizar === true || requiere_actualizar === "true") {
        navigate("/inicio/editar-perfil/")
        setLog(false)
    }else{
      setLog(false)
    }

  }, [])

  return (
    <>
    {Log && Location !== '/' && Location !== '/validacion' && Location !== '/validacion/'? 
    <Spinner />
    :
    <Provider store={store} >

        <LayoutTemplate props={props} />

    </Provider>
  }
  </>
  )
}

export default LayoutProvider
