import {
  GET_PREGUNTAS_FRECUENTES,
  GET_PREGUNTAS_SECCION,
  GET_PREGUNTAS_TIPO,
  GET_PREGUNTAS_MAP
} from "../../types"
import clienteAxios from "../../../Utils/axios"

export function ActualizaPreguntas(preguntas){
  return async dispatch => {
    
    dispatch(Get_Preguntas_Map_F(preguntas))
  }
}

export function ConsultaPreguntasF() {
  return async dispatch => {
    try {
      const tokenaccess = localStorage.getItem("token")

      const respuesta = await clienteAxios.get("ParametrosPreguntas/", {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      dispatch(Get_Frecuentes_F(respuesta.data.preguntas))
      dispatch(Get_Tipo_F(respuesta.data.tipopregunta))
      dispatch(Get_Seccion_F(respuesta.data.seccionpregunta))
      dispatch(Get_Preguntas_Map_F(respuesta.data.preguntas))
    } catch (error) {
      console.log(error)
    }
  }
}

const Get_Preguntas_Map_F = preguntas => ({
  type: GET_PREGUNTAS_MAP,
  payload: preguntas,
})

const Get_Frecuentes_F = preguntas => ({
  type: GET_PREGUNTAS_FRECUENTES,
  payload: preguntas,
})

const Get_Tipo_F = preguntas => ({
  type: GET_PREGUNTAS_TIPO,
  payload: preguntas,
})

const Get_Seccion_F = preguntas => ({
  type: GET_PREGUNTAS_SECCION,
  payload: preguntas,
})
